import React from 'react'
import Layout from '../components/Layout'
import styled from "styled-components"
import superBowlMenu from "../assets/super-bowl.png"

const superbowlSpecials = () => {
  return (
    <Layout pageTitle="Superbowl Specials" noHero>
        <Page>
            <Title>Please email catering@docksidebrewery.com for Super Bowl Preorders</Title>
            <img src={superBowlMenu}/>
        </Page>
    </Layout>
  )
}

const Page = styled.div`
  padding: 15rem 1.5rem 5rem;
  background: ${({ theme }) => theme.primary};
`
const Title = styled.p`
  font-size: 1.5rem;
  text-align: center;
  margin: 3rem 0;
  color: #111;
  font-weight: 600;
  line-height: 30px;

  @media (min-width: 768px) {
    font-size: 2rem;
    max-width: 100%;
  }
`

export default superbowlSpecials